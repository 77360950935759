import React, { useState, useRef, useEffect, useMemo } from "react";
import {
  CCard,
  CCardBody,
  CCardText,
  CCardTitle,
  CCardImage,
  CButton,
} from "@coreui/react-pro";
import CIcon from "@coreui/icons-react";
import { cilOptions, cilPencil, cilTrash } from "@coreui/icons";

import UpdatePostModal from "../../../components/post/UpdatePostModal";
import DeletePostModal from "../../../components/post/DeletePostModal";
import postServices from "../../../services/post";
import img from "../../../assets/img/blog/img-blog.jpg";

const CardPublicationSmall = ({ post }) => {
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);

  const [showOptions, setShowOptions] = useState(false);

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  const optionsRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [optionsRef]);

  const [postImage, setPostImage] = useState(null);

  useEffect(() => {
    async function getImage() {
      const res = await postServices.getPostImage(post?.image_path);
      setPostImage(res);
    }

    getImage();
  }, [post.image_path]);

  return (
    <div id="card-publication">
      <div className="container d-flex gap-5">
        <div className="cards">
          <CCard className="card-small">
            {postImage?.data?.file.type !== "text/html" ? (
              <CCardImage
                orientation="top"
                src={postImage?.data?.url}
                width={20}
              />
            ) : (
              <CCardImage orientation="top" src={img} width={20} />
            )}

            <CCardBody>
              <div className="card-align">
                <CCardText>{post.formatedDate}</CCardText>
                <div className="icon" onClick={toggleOptions}>
                  <CIcon icon={cilOptions} width={20} />
                </div>
                {showOptions && (
                  <div className="three-points" ref={optionsRef}>
                    <button onClick={() => setVisibleEdit(true)}>
                      <CIcon className="mx-2" icon={cilPencil} width={20} />
                      Editar
                    </button>
                    <button onClick={() => setVisibleDelete(true)}>
                      <CIcon className="mx-2" icon={cilTrash} width={20} />
                      Excluir
                    </button>
                  </div>
                )}
              </div>
              <CCardText>{post.view_count} visualizações</CCardText>
              <div className="card-title">
                <CCardTitle>{post.title}</CCardTitle>
              </div>
              <div className="card-description">
                <CCardText>
                  {post.description.replace(/(<([^>]+)>)/gi, "")}
                </CCardText>
              </div>
            </CCardBody>
          </CCard>
        </div>
      </div>

      {visibleEdit && (
        <UpdatePostModal
          visible={visibleEdit}
          hideModal={() => setVisibleEdit(false)}
          post={{ ...post, image: postImage?.data }}
        />
      )}
      {visibleDelete && (
        <DeletePostModal
          visible={visibleDelete}
          hideModal={() => setVisibleDelete(false)}
          postId={post.id}
        />
      )}
    </div>
  );
};

export default React.memo(CardPublicationSmall);